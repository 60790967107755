import { addHours, subHours } from 'date-fns';
import { LocalisedLabel } from '../../../../lib';
import useNow from '../../../../useNow';
import { useHistoricalPlantData, useHistoricalSoftSensors, useHistoricalTargets, useUnit } from '../../../../utils';
import { QualityChartConfig } from '../../schema';
import ChartErrorMessage from '../ChartErrorMessage';
import ChartLoadingMessage from '../ChartLoadingMessage';
import ChartTitle from '../ChartTitle';
import ChartContainer from './ChartContainer';

type Props = {
  config: QualityChartConfig;
};
const QualitySection = ({ config }: Props) => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const endDate = addHours(now, 6);
  const oldTargetStartDate = subHours(now, 48);
  const { label, modelled, measured, sampleRate } = config;
  const {
    data: measuredData,
    isLoading: isMeasuredDataLoading,
    isError: isMeasuredDataError
  } = useHistoricalPlantData([measured.name], subHours(startDate, 1), endDate, sampleRate || 15);
  const {
    data: modelledData,
    isLoading: isModelledDataLoading,
    isError: isModelledDataError
  } = useHistoricalSoftSensors([modelled.name], subHours(startDate, 1), endDate, true);
  const {
    data: targetData,
    isLoading: isTargetDataLoading,
    isError: isTargetDataError
  } = useHistoricalTargets([modelled.name], subHours(startDate, 1), endDate, true);
  const {
    data: oldTargetData,
    isLoading: isOldTargetDataLoading,
    isError: isOldTargetDataError
  } = useHistoricalTargets([modelled.name], oldTargetStartDate, subHours(startDate, 1), true);
  const { data: unit, isLoading: isLoadingUnit, isError: isUnitError } = useUnit(measured.name);
  const isLoading =
    isMeasuredDataLoading || isModelledDataLoading || isLoadingUnit || isTargetDataLoading || isOldTargetDataLoading;
  const isError =
    isMeasuredDataError || isModelledDataError || isUnitError || isTargetDataError || isOldTargetDataError;
  return (
    <section>
      <ChartTitle>
        <LocalisedLabel>{label}</LocalisedLabel> ({unit})
      </ChartTitle>
      {isLoading && <ChartLoadingMessage />}
      {isError && <ChartErrorMessage />}
      {!isLoading && !isError && (
        <ChartContainer
          modelledData={modelledData}
          measuredData={measuredData}
          modelledLabel={modelled.label}
          measuredLabel={measured.label}
          targetData={targetData}
          oldTargetData={oldTargetData}
          startDate={startDate}
          endDate={endDate}
          config={config}
          unit={unit}
        />
      )}
    </section>
  );
};

export default QualitySection;
