[
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T12:00:00+00:00",
    "value": 2.0172,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T12:15:00+00:00",
    "value": 2.0172,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T12:30:00+00:00",
    "value": 2.0172,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T12:45:00+00:00",
    "value": 2.0172,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T13:00:00+00:00",
    "value": 3.0841,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T13:15:00+00:00",
    "value": 3.0841,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T13:30:00+00:00",
    "value": 3.0841,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T13:45:00+00:00",
    "value": 3.0841,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T14:00:00+00:00",
    "value": 3.0841,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T14:15:00+00:00",
    "value": 3.0841,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T14:30:00+00:00",
    "value": 3.0841,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T14:45:00+00:00",
    "value": 3.2222,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T15:00:00+00:00",
    "value": 3.2222,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T15:15:00+00:00",
    "value": 5.2906,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T15:30:00+00:00",
    "value": 5.2906,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T15:45:00+00:00",
    "value": 5.2906,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T16:00:00+00:00",
    "value": 5.2906,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T16:15:00+00:00",
    "value": 5.2906,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T16:30:00+00:00",
    "value": 5.2906,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T16:45:00+00:00",
    "value": 5.417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T17:00:00+00:00",
    "value": 5.417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T17:15:00+00:00",
    "value": 5.417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T17:30:00+00:00",
    "value": 4.4831,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T17:45:00+00:00",
    "value": 4.4831,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T18:00:00+00:00",
    "value": 4.4831,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T18:15:00+00:00",
    "value": 4.4831,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T18:30:00+00:00",
    "value": 4.4831,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T18:45:00+00:00",
    "value": 4.4831,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T19:00:00+00:00",
    "value": 4.4831,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T19:15:00+00:00",
    "value": 5.0146,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T19:30:00+00:00",
    "value": 6.2242,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T19:45:00+00:00",
    "value": 6.2242,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T20:00:00+00:00",
    "value": 6.2242,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T20:15:00+00:00",
    "value": 6.2242,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T20:30:00+00:00",
    "value": 6.2242,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T20:45:00+00:00",
    "value": 6.2242,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T21:00:00+00:00",
    "value": 6.3895,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T21:15:00+00:00",
    "value": 6.3895,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T21:30:00+00:00",
    "value": 6.9569,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T21:45:00+00:00",
    "value": 6.9569,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T22:00:00+00:00",
    "value": 6.9569,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T22:15:00+00:00",
    "value": 6.9569,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T22:30:00+00:00",
    "value": 6.9569,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T22:45:00+00:00",
    "value": 6.7433,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T23:00:00+00:00",
    "value": 6.7433,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T23:15:00+00:00",
    "value": 6.4068,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T23:30:00+00:00",
    "value": 6.4068,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-09T23:45:00+00:00",
    "value": 6.4068,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T00:00:00+00:00",
    "value": 6.4068,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T00:15:00+00:00",
    "value": 6.4068,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T00:30:00+00:00",
    "value": 6.4068,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T00:45:00+00:00",
    "value": 6.4068,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T01:00:00+00:00",
    "value": 6.4749,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T01:15:00+00:00",
    "value": 5.6262,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T01:30:00+00:00",
    "value": 5.6262,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T01:45:00+00:00",
    "value": 5.6262,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T02:00:00+00:00",
    "value": 5.6262,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T02:15:00+00:00",
    "value": 5.6262,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T02:30:00+00:00",
    "value": 5.6262,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T02:45:00+00:00",
    "value": 5.6262,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T03:00:00+00:00",
    "value": 5.7707,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T03:15:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T03:30:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T03:45:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T04:00:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T04:15:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T04:30:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T04:45:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T05:00:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T05:15:00+00:00",
    "value": 5.3273,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T05:30:00+00:00",
    "value": 4.4238,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T05:45:00+00:00",
    "value": 4.4238,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T06:00:00+00:00",
    "value": 4.4238,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T06:15:00+00:00",
    "value": 4.4238,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T06:30:00+00:00",
    "value": 4.4238,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T06:45:00+00:00",
    "value": 4.4238,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T07:00:00+00:00",
    "value": 4.5355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T07:15:00+00:00",
    "value": 4.5355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T07:30:00+00:00",
    "value": 4.6509,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T07:45:00+00:00",
    "value": 4.6509,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T08:00:00+00:00",
    "value": 4.6509,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T08:15:00+00:00",
    "value": 4.6509,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T08:30:00+00:00",
    "value": 4.6509,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T08:45:00+00:00",
    "value": 4.6509,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T09:00:00+00:00",
    "value": 5.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T09:15:00+00:00",
    "value": 5.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T09:30:00+00:00",
    "value": 5.6253,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T09:45:00+00:00",
    "value": 5.6253,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T10:00:00+00:00",
    "value": 5.6253,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T10:15:00+00:00",
    "value": 5.6253,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T10:30:00+00:00",
    "value": 5.6253,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T10:45:00+00:00",
    "value": 5.6253,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T11:00:00+00:00",
    "value": 7.1542,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T11:15:00+00:00",
    "value": 7.1542,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T11:30:00+00:00",
    "value": 6.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T11:45:00+00:00",
    "value": 6.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T12:00:00+00:00",
    "value": 6.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T12:15:00+00:00",
    "value": 6.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T12:30:00+00:00",
    "value": 6.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T12:45:00+00:00",
    "value": 6.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T13:00:00+00:00",
    "value": 7.1651,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T13:15:00+00:00",
    "value": 7.6522,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T13:30:00+00:00",
    "value": 7.6522,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T13:45:00+00:00",
    "value": 7.6522,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T14:00:00+00:00",
    "value": 7.6522,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T14:15:00+00:00",
    "value": 7.6522,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T14:30:00+00:00",
    "value": 7.6522,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T14:45:00+00:00",
    "value": 7.6522,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T15:00:00+00:00",
    "value": 7.4103,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T15:15:00+00:00",
    "value": 7.0972,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T15:30:00+00:00",
    "value": 7.0972,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T15:45:00+00:00",
    "value": 7.0972,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T16:00:00+00:00",
    "value": 7.0972,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T16:15:00+00:00",
    "value": 7.0972,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T16:30:00+00:00",
    "value": 7.0972,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T16:45:00+00:00",
    "value": 7.0972,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T17:00:00+00:00",
    "value": 7.9906,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T17:15:00+00:00",
    "value": 7.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T17:30:00+00:00",
    "value": 7.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T17:45:00+00:00",
    "value": 7.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T18:00:00+00:00",
    "value": 7.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T18:15:00+00:00",
    "value": 7.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T18:30:00+00:00",
    "value": 7.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T18:45:00+00:00",
    "value": 7.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T19:00:00+00:00",
    "value": 7.6129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T19:15:00+00:00",
    "value": 7.918,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T19:30:00+00:00",
    "value": 7.918,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T19:45:00+00:00",
    "value": 7.918,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T20:00:00+00:00",
    "value": 7.918,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T20:15:00+00:00",
    "value": 7.918,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T20:30:00+00:00",
    "value": 7.918,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T20:45:00+00:00",
    "value": 7.918,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T21:00:00+00:00",
    "value": 7.912,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T21:15:00+00:00",
    "value": 7.4618,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T21:30:00+00:00",
    "value": 7.4618,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T21:45:00+00:00",
    "value": 7.4618,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T22:00:00+00:00",
    "value": 7.4618,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T22:15:00+00:00",
    "value": 7.4618,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T22:30:00+00:00",
    "value": 7.4618,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T22:45:00+00:00",
    "value": 7.4618,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T23:00:00+00:00",
    "value": 8.2414,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T23:15:00+00:00",
    "value": 8.0374,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T23:30:00+00:00",
    "value": 8.0374,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-10T23:45:00+00:00",
    "value": 8.0374,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T00:00:00+00:00",
    "value": 8.0374,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T00:15:00+00:00",
    "value": 8.0374,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T00:30:00+00:00",
    "value": 8.0374,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T00:45:00+00:00",
    "value": 8.0141,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T01:00:00+00:00",
    "value": 8.0141,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T01:15:00+00:00",
    "value": 8.0015,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T01:30:00+00:00",
    "value": 8.0015,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T01:45:00+00:00",
    "value": 8.0015,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T02:00:00+00:00",
    "value": 8.0015,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T02:15:00+00:00",
    "value": 8.0015,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T02:30:00+00:00",
    "value": 8.0015,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T02:45:00+00:00",
    "value": 8.0015,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T03:00:00+00:00",
    "value": 8.0015,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T03:15:00+00:00",
    "value": 8.2212,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T03:30:00+00:00",
    "value": 8.2212,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T03:45:00+00:00",
    "value": 8.201,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T04:00:00+00:00",
    "value": 8.201,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T04:15:00+00:00",
    "value": 8.201,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T04:30:00+00:00",
    "value": 8.201,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T04:45:00+00:00",
    "value": 8.4041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T05:00:00+00:00",
    "value": 8.4041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T05:15:00+00:00",
    "value": 8.1572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T05:30:00+00:00",
    "value": 8.1572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T05:45:00+00:00",
    "value": 8.1572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T06:00:00+00:00",
    "value": 8.1572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T06:15:00+00:00",
    "value": 8.1572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T06:30:00+00:00",
    "value": 8.1572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T06:45:00+00:00",
    "value": 8.1572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T07:00:00+00:00",
    "value": 7.6217,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T07:15:00+00:00",
    "value": 7.6061,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T07:30:00+00:00",
    "value": 7.6061,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T07:45:00+00:00",
    "value": 7.6061,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T08:00:00+00:00",
    "value": 7.6061,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T08:15:00+00:00",
    "value": 7.6061,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T08:30:00+00:00",
    "value": 7.6061,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T08:45:00+00:00",
    "value": 7.6061,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T09:00:00+00:00",
    "value": 7.6061,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T09:15:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T09:30:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T09:45:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T10:00:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T10:15:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T10:30:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T10:45:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T11:00:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T11:15:00+00:00",
    "value": 7.136,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T11:30:00+00:00",
    "value": 7.9934,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T11:45:00+00:00",
    "value": 7.9934,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T12:00:00+00:00",
    "value": 7.9934,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T12:15:00+00:00",
    "value": 7.9934,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T12:30:00+00:00",
    "value": 7.9934,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T12:45:00+00:00",
    "value": 7.9934,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T13:00:00+00:00",
    "value": 7.9934,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T13:15:00+00:00",
    "value": 6.5518,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T13:30:00+00:00",
    "value": 6.5518,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T13:45:00+00:00",
    "value": 6.6542,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T14:00:00+00:00",
    "value": 6.6542,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T14:15:00+00:00",
    "value": 6.6542,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T14:30:00+00:00",
    "value": 6.6542,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T14:45:00+00:00",
    "value": 6.6542,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T15:00:00+00:00",
    "value": 6.6542,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T15:15:00+00:00",
    "value": 7.0336,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T15:30:00+00:00",
    "value": 7.0336,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T15:45:00+00:00",
    "value": 7.0336,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T16:00:00+00:00",
    "value": 7.0336,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T16:15:00+00:00",
    "value": 7.0336,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T16:30:00+00:00",
    "value": 7.0336,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T16:45:00+00:00",
    "value": 6.8484,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T17:00:00+00:00",
    "value": 6.8484,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T17:15:00+00:00",
    "value": 6.8484,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T17:30:00+00:00",
    "value": 5.8515,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T17:45:00+00:00",
    "value": 5.4422,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T18:00:00+00:00",
    "value": 5.4422,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T18:15:00+00:00",
    "value": 5.4422,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T18:30:00+00:00",
    "value": 5.4422,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T18:45:00+00:00",
    "value": 5.4422,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T19:00:00+00:00",
    "value": 5.4422,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T19:15:00+00:00",
    "value": 4.9032,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T19:30:00+00:00",
    "value": 4.9032,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T19:45:00+00:00",
    "value": 4.9032,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T20:00:00+00:00",
    "value": 5.3294,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T20:15:00+00:00",
    "value": 5.3294,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T20:30:00+00:00",
    "value": 4.4265,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T20:45:00+00:00",
    "value": 4.4265,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T21:00:00+00:00",
    "value": 4.4265,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T21:15:00+00:00",
    "value": 4.4265,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T21:30:00+00:00",
    "value": 3.8396,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T21:45:00+00:00",
    "value": 3.8396,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T22:00:00+00:00",
    "value": 3.8396,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T22:15:00+00:00",
    "value": 3.8396,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T22:30:00+00:00",
    "value": 3.8396,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T22:45:00+00:00",
    "value": 5.2707,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T23:00:00+00:00",
    "value": 5.2707,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T23:15:00+00:00",
    "value": 5.2465,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T23:30:00+00:00",
    "value": 5.2465,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-11T23:45:00+00:00",
    "value": 5.2465,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T00:00:00+00:00",
    "value": 5.2465,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T00:15:00+00:00",
    "value": 5.2465,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T00:30:00+00:00",
    "value": 5.2465,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T00:45:00+00:00",
    "value": 5.2465,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T01:00:00+00:00",
    "value": 4.4205,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T01:15:00+00:00",
    "value": 4.4205,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T01:30:00+00:00",
    "value": 4.4205,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T01:45:00+00:00",
    "value": 3.772,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T02:00:00+00:00",
    "value": 3.772,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T02:15:00+00:00",
    "value": 3.772,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T02:30:00+00:00",
    "value": 3.772,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T02:45:00+00:00",
    "value": 3.772,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T03:00:00+00:00",
    "value": 3.772,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T03:15:00+00:00",
    "value": 4.4735,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T03:30:00+00:00",
    "value": 4.4735,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T03:45:00+00:00",
    "value": 4.4735,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T04:00:00+00:00",
    "value": 4.4735,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T04:15:00+00:00",
    "value": 4.4735,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T04:30:00+00:00",
    "value": 4.4735,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T04:45:00+00:00",
    "value": 4.4735,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T05:00:00+00:00",
    "value": 4.4735,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T05:15:00+00:00",
    "value": 4.486,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T05:30:00+00:00",
    "value": 4.2369,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T05:45:00+00:00",
    "value": 4.2369,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T06:00:00+00:00",
    "value": 4.2369,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T06:15:00+00:00",
    "value": 4.2369,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T06:30:00+00:00",
    "value": 4.2369,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T06:45:00+00:00",
    "value": 4.2369,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T07:00:00+00:00",
    "value": 4.0038,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T07:15:00+00:00",
    "value": 4.5728,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T07:30:00+00:00",
    "value": 4.5728,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T07:45:00+00:00",
    "value": 4.5728,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T08:00:00+00:00",
    "value": 4.5728,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T08:15:00+00:00",
    "value": 4.5728,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T08:30:00+00:00",
    "value": 4.5728,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T08:45:00+00:00",
    "value": 4.5728,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T09:00:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T09:15:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T09:30:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T09:45:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T10:00:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T10:15:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T10:30:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T10:45:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T11:00:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T11:15:00+00:00",
    "value": 5.9822,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T11:30:00+00:00",
    "value": 7.554,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T11:45:00+00:00",
    "value": 7.554,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T12:00:00+00:00",
    "value": 7.554,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T12:15:00+00:00",
    "value": 7.554,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T12:30:00+00:00",
    "value": 7.554,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T12:45:00+00:00",
    "value": 7.554,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T13:00:00+00:00",
    "value": 7.554,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T13:15:00+00:00",
    "value": 6.7854,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T13:30:00+00:00",
    "value": 6.7854,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T13:45:00+00:00",
    "value": 6.5456,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T14:00:00+00:00",
    "value": 6.5456,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T14:15:00+00:00",
    "value": 6.5456,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T14:30:00+00:00",
    "value": 6.5456,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T14:45:00+00:00",
    "value": 6.5456,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T15:00:00+00:00",
    "value": 6.5261,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T15:15:00+00:00",
    "value": 6.8649,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T15:30:00+00:00",
    "value": 6.8649,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T15:45:00+00:00",
    "value": 6.8649,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T16:00:00+00:00",
    "value": 6.8649,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T16:15:00+00:00",
    "value": 6.8649,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T16:30:00+00:00",
    "value": 6.8649,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T16:45:00+00:00",
    "value": 7.3828,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T17:00:00+00:00",
    "value": 7.3828,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T17:15:00+00:00",
    "value": 7.3828,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T17:30:00+00:00",
    "value": 7.3828,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T17:45:00+00:00",
    "value": 6.9355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T18:00:00+00:00",
    "value": 6.9355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T18:15:00+00:00",
    "value": 6.9355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T18:30:00+00:00",
    "value": 6.9355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T18:45:00+00:00",
    "value": 6.9355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T19:00:00+00:00",
    "value": 6.9355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T19:15:00+00:00",
    "value": 6.9355,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T19:30:00+00:00",
    "value": 7.4486,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T19:45:00+00:00",
    "value": 7.4486,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T20:00:00+00:00",
    "value": 7.4486,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T20:15:00+00:00",
    "value": 7.4486,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T20:30:00+00:00",
    "value": 7.4486,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T20:45:00+00:00",
    "value": 7.4486,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T21:00:00+00:00",
    "value": 7.806,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T21:15:00+00:00",
    "value": 7.806,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T21:30:00+00:00",
    "value": 7.9729,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T21:45:00+00:00",
    "value": 7.9729,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T22:00:00+00:00",
    "value": 7.9729,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T22:15:00+00:00",
    "value": 7.9729,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T22:30:00+00:00",
    "value": 7.9729,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T22:45:00+00:00",
    "value": 7.3187,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T23:00:00+00:00",
    "value": 7.3187,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T23:15:00+00:00",
    "value": 7.3187,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T23:30:00+00:00",
    "value": 7.4594,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-12T23:45:00+00:00",
    "value": 7.4594,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T00:00:00+00:00",
    "value": 7.4594,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T00:15:00+00:00",
    "value": 7.4594,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T00:30:00+00:00",
    "value": 7.4594,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T00:45:00+00:00",
    "value": 7.4594,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T01:00:00+00:00",
    "value": 7.4594,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T01:15:00+00:00",
    "value": 8.3714,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T01:30:00+00:00",
    "value": 8.4175,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T01:45:00+00:00",
    "value": 8.4175,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T02:00:00+00:00",
    "value": 8.4175,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T02:15:00+00:00",
    "value": 8.4175,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T02:30:00+00:00",
    "value": 8.4175,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T02:45:00+00:00",
    "value": 8.4175,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T03:00:00+00:00",
    "value": 8.8699,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T03:15:00+00:00",
    "value": 8.9666,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T03:30:00+00:00",
    "value": 8.9666,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T03:45:00+00:00",
    "value": 8.9666,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T04:00:00+00:00",
    "value": 8.9666,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T04:15:00+00:00",
    "value": 8.9666,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T04:30:00+00:00",
    "value": 8.9666,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T04:45:00+00:00",
    "value": 8.9666,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T05:00:00+00:00",
    "value": 8.9666,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T05:15:00+00:00",
    "value": 9.0272,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T05:30:00+00:00",
    "value": 8.6527,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T05:45:00+00:00",
    "value": 8.6527,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T06:00:00+00:00",
    "value": 8.6527,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T06:15:00+00:00",
    "value": 8.6527,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T06:30:00+00:00",
    "value": 8.6527,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T06:45:00+00:00",
    "value": 8.6527,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T07:00:00+00:00",
    "value": 8.6527,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T07:15:00+00:00",
    "value": 10.1066,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T07:30:00+00:00",
    "value": 10.1066,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T07:45:00+00:00",
    "value": 10.1066,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T08:00:00+00:00",
    "value": 10.1066,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T08:15:00+00:00",
    "value": 10.1066,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T08:30:00+00:00",
    "value": 10.1066,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T08:45:00+00:00",
    "value": 10.1066,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T09:00:00+00:00",
    "value": 10.9343,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T09:15:00+00:00",
    "value": 11.1417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T09:30:00+00:00",
    "value": 11.1417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T09:45:00+00:00",
    "value": 11.1417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T10:00:00+00:00",
    "value": 11.1417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T10:15:00+00:00",
    "value": 11.1417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T10:30:00+00:00",
    "value": 11.1417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T10:45:00+00:00",
    "value": 11.1417,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T11:00:00+00:00",
    "value": 11.2297,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T11:15:00+00:00",
    "value": 11.2297,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T11:30:00+00:00",
    "value": 11.4767,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T11:45:00+00:00",
    "value": 11.4767,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T12:00:00+00:00",
    "value": 11.4767,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T12:15:00+00:00",
    "value": 11.4767,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T12:30:00+00:00",
    "value": 11.4767,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T12:45:00+00:00",
    "value": 11.4767,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T13:00:00+00:00",
    "value": 11.4767,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T13:15:00+00:00",
    "value": 12.0686,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T13:30:00+00:00",
    "value": 12.1096,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T13:45:00+00:00",
    "value": 12.1096,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T14:00:00+00:00",
    "value": 12.1096,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T14:15:00+00:00",
    "value": 12.1096,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T14:30:00+00:00",
    "value": 12.1096,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T14:45:00+00:00",
    "value": 12.1096,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T15:00:00+00:00",
    "value": 12.1096,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T15:15:00+00:00",
    "value": 11.2064,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T15:30:00+00:00",
    "value": 11.2064,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T15:45:00+00:00",
    "value": 11.2064,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T16:00:00+00:00",
    "value": 11.2064,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T16:15:00+00:00",
    "value": 11.2064,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T16:30:00+00:00",
    "value": 11.2064,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T16:45:00+00:00",
    "value": 11.2064,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T17:00:00+00:00",
    "value": 11.4409,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T17:15:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T17:30:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T17:45:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T18:00:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T18:15:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T18:30:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T18:45:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T19:00:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T19:15:00+00:00",
    "value": 11.8017,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T19:30:00+00:00",
    "value": 12.429,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T19:45:00+00:00",
    "value": 12.429,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T20:00:00+00:00",
    "value": 12.429,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T20:15:00+00:00",
    "value": 12.429,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T20:30:00+00:00",
    "value": 12.429,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T20:45:00+00:00",
    "value": 12.429,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T21:00:00+00:00",
    "value": 12.5182,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T21:15:00+00:00",
    "value": 12.5182,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T21:30:00+00:00",
    "value": 12.3634,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T21:45:00+00:00",
    "value": 12.3634,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T22:00:00+00:00",
    "value": 12.3634,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T22:15:00+00:00",
    "value": 12.3634,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T22:30:00+00:00",
    "value": 12.3634,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T22:45:00+00:00",
    "value": 12.3634,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T23:00:00+00:00",
    "value": 12.353,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T23:15:00+00:00",
    "value": 12.353,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T23:30:00+00:00",
    "value": 13.2209,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-13T23:45:00+00:00",
    "value": 13.2209,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T00:00:00+00:00",
    "value": 13.2209,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T00:15:00+00:00",
    "value": 13.2209,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T00:30:00+00:00",
    "value": 13.2209,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T00:45:00+00:00",
    "value": 13.2209,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T01:00:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T01:15:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T01:30:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T01:45:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T02:00:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T02:15:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T02:30:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T02:45:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T03:00:00+00:00",
    "value": 12.5816,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T03:15:00+00:00",
    "value": 13.3923,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T03:30:00+00:00",
    "value": 13.3923,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T03:45:00+00:00",
    "value": 13.3923,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T04:00:00+00:00",
    "value": 13.3923,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T04:15:00+00:00",
    "value": 13.3923,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T04:30:00+00:00",
    "value": 13.3923,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T04:45:00+00:00",
    "value": 13.3923,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T05:00:00+00:00",
    "value": 13.3923,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T05:15:00+00:00",
    "value": 13.0328,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T05:30:00+00:00",
    "value": 12.5575,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T05:45:00+00:00",
    "value": 12.5575,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T06:00:00+00:00",
    "value": 12.5575,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T06:15:00+00:00",
    "value": 12.5575,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T06:30:00+00:00",
    "value": 12.5575,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T06:45:00+00:00",
    "value": 12.5575,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T07:00:00+00:00",
    "value": 12.5575,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T07:15:00+00:00",
    "value": 12.8052,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T07:30:00+00:00",
    "value": 12.8052,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T07:45:00+00:00",
    "value": 12.8052,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T08:00:00+00:00",
    "value": 12.8052,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T08:15:00+00:00",
    "value": 12.8052,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T08:30:00+00:00",
    "value": 12.8052,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T08:45:00+00:00",
    "value": 12.8052,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T09:00:00+00:00",
    "value": 12.6496,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T09:15:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T09:30:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T09:45:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T10:00:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T10:15:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T10:30:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T10:45:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T11:00:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T11:15:00+00:00",
    "value": 13.4611,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T11:30:00+00:00",
    "value": 13.1189,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T11:45:00+00:00",
    "value": 13.4002,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T12:00:00+00:00",
    "value": 13.4002,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T12:15:00+00:00",
    "value": 13.4002,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T12:30:00+00:00",
    "value": 13.4002,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T12:45:00+00:00",
    "value": 13.4002,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T13:00:00+00:00",
    "value": 13.3091,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T13:15:00+00:00",
    "value": 13.3091,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T13:30:00+00:00",
    "value": 13.3091,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T13:45:00+00:00",
    "value": 13.5634,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T14:00:00+00:00",
    "value": 13.5634,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T14:15:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T14:30:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T14:45:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T15:00:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T15:15:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T15:30:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T15:45:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T16:00:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T16:15:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T16:30:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T16:45:00+00:00",
    "value": 13.9723,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T17:00:00+00:00",
    "value": 14.9687,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T17:15:00+00:00",
    "value": 14.9687,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T17:30:00+00:00",
    "value": 14.9687,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T17:45:00+00:00",
    "value": 13.5612,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T18:00:00+00:00",
    "value": 13.5612,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T18:15:00+00:00",
    "value": 13.5612,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T18:30:00+00:00",
    "value": 13.5612,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T18:45:00+00:00",
    "value": 13.5612,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T19:00:00+00:00",
    "value": 13.5612,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T19:15:00+00:00",
    "value": 13.5612,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T19:30:00+00:00",
    "value": 13.9187,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T19:45:00+00:00",
    "value": 13.9187,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T20:00:00+00:00",
    "value": 13.6102,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T20:15:00+00:00",
    "value": 13.6102,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T20:30:00+00:00",
    "value": 13.6102,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T20:45:00+00:00",
    "value": 13.6102,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T21:00:00+00:00",
    "value": 13.5286,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T21:15:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T21:30:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T21:45:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T22:00:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T22:15:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T22:30:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T22:45:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T23:00:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T23:15:00+00:00",
    "value": 13.1999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T23:30:00+00:00",
    "value": 14.1548,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-14T23:45:00+00:00",
    "value": 14.1548,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T00:00:00+00:00",
    "value": 14.1548,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T00:15:00+00:00",
    "value": 14.1548,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T00:30:00+00:00",
    "value": 14.1548,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T00:45:00+00:00",
    "value": 13.9299,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T01:00:00+00:00",
    "value": 13.9299,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T01:15:00+00:00",
    "value": 13.1204,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T01:30:00+00:00",
    "value": 13.1204,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T01:45:00+00:00",
    "value": 13.1204,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T02:00:00+00:00",
    "value": 13.1204,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T02:15:00+00:00",
    "value": 13.1204,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T02:30:00+00:00",
    "value": 13.1204,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T02:45:00+00:00",
    "value": 13.1204,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T03:00:00+00:00",
    "value": 13.1204,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T03:15:00+00:00",
    "value": 13.4624,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T03:30:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T03:45:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T04:00:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T04:15:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T04:30:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T04:45:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T05:00:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T05:15:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T05:30:00+00:00",
    "value": 13.9004,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T05:45:00+00:00",
    "value": 14.2159,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T06:00:00+00:00",
    "value": 14.2159,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T06:15:00+00:00",
    "value": 14.2159,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T06:30:00+00:00",
    "value": 14.2159,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T06:45:00+00:00",
    "value": 14.2159,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T07:00:00+00:00",
    "value": 14.2159,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T07:15:00+00:00",
    "value": 14.227,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T07:30:00+00:00",
    "value": 16.2099,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T07:45:00+00:00",
    "value": 16.2099,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T08:00:00+00:00",
    "value": 16.2099,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T08:15:00+00:00",
    "value": 16.2099,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T08:30:00+00:00",
    "value": 16.2099,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T08:45:00+00:00",
    "value": 16.2099,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T09:00:00+00:00",
    "value": 16.2099,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T09:15:00+00:00",
    "value": 16.2099,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T09:30:00+00:00",
    "value": 15.5994,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T09:45:00+00:00",
    "value": 15.5994,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T10:00:00+00:00",
    "value": 15.5994,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T10:15:00+00:00",
    "value": 15.5994,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T10:30:00+00:00",
    "value": 15.5994,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T10:45:00+00:00",
    "value": 15.5994,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T11:00:00+00:00",
    "value": 15.5994,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T11:15:00+00:00",
    "value": 15.5994,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T11:30:00+00:00",
    "value": 16.0309,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T11:45:00+00:00",
    "value": 16.0309,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T12:00:00+00:00",
    "value": 16.0309,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T12:15:00+00:00",
    "value": 16.0309,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T12:30:00+00:00",
    "value": 16.0309,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T12:45:00+00:00",
    "value": 15.8254,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T13:00:00+00:00",
    "value": 15.8254,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T13:15:00+00:00",
    "value": 15.8254,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T13:30:00+00:00",
    "value": 15.4378,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T13:45:00+00:00",
    "value": 15.4378,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T14:00:00+00:00",
    "value": 15.4378,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T14:15:00+00:00",
    "value": 15.4378,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T14:30:00+00:00",
    "value": 15.4378,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T14:45:00+00:00",
    "value": 15.4378,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T15:00:00+00:00",
    "value": 15.4378,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T15:15:00+00:00",
    "value": 15.7751,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T15:30:00+00:00",
    "value": 15.9152,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T15:45:00+00:00",
    "value": 15.9152,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T16:00:00+00:00",
    "value": 15.9152,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T16:15:00+00:00",
    "value": 15.9152,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T16:30:00+00:00",
    "value": 15.9152,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T16:45:00+00:00",
    "value": 15.9152,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T17:00:00+00:00",
    "value": 15.5018,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T17:15:00+00:00",
    "value": 15.5018,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T17:30:00+00:00",
    "value": 15.3897,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T17:45:00+00:00",
    "value": 15.3897,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T18:00:00+00:00",
    "value": 15.3897,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T18:15:00+00:00",
    "value": 15.3897,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T18:30:00+00:00",
    "value": 15.3897,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T18:45:00+00:00",
    "value": 15.3897,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T19:00:00+00:00",
    "value": 15.3897,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T19:15:00+00:00",
    "value": 15.4047,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T19:30:00+00:00",
    "value": 15.4047,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T19:45:00+00:00",
    "value": 15.4047,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T20:00:00+00:00",
    "value": 15.4047,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T20:15:00+00:00",
    "value": 15.4047,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T20:30:00+00:00",
    "value": 15.4047,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T20:45:00+00:00",
    "value": 15.4047,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T21:00:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T21:15:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T21:30:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T21:45:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T22:00:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T22:15:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T22:30:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T22:45:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T23:00:00+00:00",
    "value": 15.2127,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T23:15:00+00:00",
    "value": 14.0487,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T23:30:00+00:00",
    "value": 14.0487,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-15T23:45:00+00:00",
    "value": 14.0487,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T00:00:00+00:00",
    "value": 14.0487,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T00:15:00+00:00",
    "value": 14.0487,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T00:30:00+00:00",
    "value": 14.0487,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T00:45:00+00:00",
    "value": 14.0487,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T01:00:00+00:00",
    "value": 14.0487,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T01:15:00+00:00",
    "value": 14.7376,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T01:30:00+00:00",
    "value": 14.7058,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T01:45:00+00:00",
    "value": 14.7058,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T02:00:00+00:00",
    "value": 14.7058,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T02:15:00+00:00",
    "value": 14.7058,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T02:30:00+00:00",
    "value": 14.7058,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T02:45:00+00:00",
    "value": 14.7058,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T03:00:00+00:00",
    "value": 14.7058,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T03:15:00+00:00",
    "value": 15.2878,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T03:30:00+00:00",
    "value": 14.0109,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T03:45:00+00:00",
    "value": 14.0109,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T04:00:00+00:00",
    "value": 14.0109,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T04:15:00+00:00",
    "value": 14.0109,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T04:30:00+00:00",
    "value": 14.0109,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T04:45:00+00:00",
    "value": 14.0109,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T05:00:00+00:00",
    "value": 14.0109,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T05:15:00+00:00",
    "value": 14.3807,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T05:30:00+00:00",
    "value": 14.3807,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T05:45:00+00:00",
    "value": 14.3807,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T06:00:00+00:00",
    "value": 14.3807,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T06:15:00+00:00",
    "value": 14.3807,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T06:30:00+00:00",
    "value": 14.3807,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T06:45:00+00:00",
    "value": 14.3807,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T07:00:00+00:00",
    "value": 14.3807,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T07:15:00+00:00",
    "value": 14.5325,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T07:30:00+00:00",
    "value": 14.5325,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T07:45:00+00:00",
    "value": 14.8441,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T08:00:00+00:00",
    "value": 14.8441,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T08:15:00+00:00",
    "value": 14.8441,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T08:30:00+00:00",
    "value": 14.8441,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T08:45:00+00:00",
    "value": 14.8441,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T09:00:00+00:00",
    "value": 14.8441,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T09:15:00+00:00",
    "value": 14.8989,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T09:30:00+00:00",
    "value": 14.5372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T09:45:00+00:00",
    "value": 14.5372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T10:00:00+00:00",
    "value": 14.5372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T10:15:00+00:00",
    "value": 14.5372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T10:30:00+00:00",
    "value": 14.5372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T10:45:00+00:00",
    "value": 14.5372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T11:00:00+00:00",
    "value": 14.5372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T11:15:00+00:00",
    "value": 14.5372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T11:30:00+00:00",
    "value": 14.0821,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T11:45:00+00:00",
    "value": 14.4134,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T12:00:00+00:00",
    "value": 14.4134,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T12:15:00+00:00",
    "value": 14.4134,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T12:30:00+00:00",
    "value": 14.4134,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T12:45:00+00:00",
    "value": 14.4134,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T13:00:00+00:00",
    "value": 14.3063,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T13:15:00+00:00",
    "value": 14.3063,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T13:30:00+00:00",
    "value": 14.3063,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T13:45:00+00:00",
    "value": 14.4646,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T14:00:00+00:00",
    "value": 14.4646,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T14:15:00+00:00",
    "value": 14.4646,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T14:30:00+00:00",
    "value": 14.4646,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T14:45:00+00:00",
    "value": 14.5651,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T15:00:00+00:00",
    "value": 14.5651,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T15:15:00+00:00",
    "value": 14.5651,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T15:30:00+00:00",
    "value": 14.5651,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T15:45:00+00:00",
    "value": 14.4535,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T16:00:00+00:00",
    "value": 14.4535,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T16:15:00+00:00",
    "value": 14.4535,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T16:30:00+00:00",
    "value": 14.4535,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T16:45:00+00:00",
    "value": 14.2995,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T17:00:00+00:00",
    "value": 14.2995,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T17:15:00+00:00",
    "value": 14.2995,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T17:30:00+00:00",
    "value": 14.1266,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T17:45:00+00:00",
    "value": 14.1266,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T18:00:00+00:00",
    "value": 14.1266,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T18:15:00+00:00",
    "value": 14.1266,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T18:30:00+00:00",
    "value": 14.1266,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T18:45:00+00:00",
    "value": 14.1266,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T19:00:00+00:00",
    "value": 14.1266,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T19:15:00+00:00",
    "value": 13.7773,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T19:30:00+00:00",
    "value": 13.6163,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T19:45:00+00:00",
    "value": 13.6163,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T20:00:00+00:00",
    "value": 13.6163,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T20:15:00+00:00",
    "value": 13.6163,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T20:30:00+00:00",
    "value": 13.6163,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T20:45:00+00:00",
    "value": 13.6163,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T21:00:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T21:15:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T21:30:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T21:45:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T22:00:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T22:15:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T22:30:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T22:45:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T23:00:00+00:00",
    "value": 13.6667,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T23:15:00+00:00",
    "value": 13.8696,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T23:30:00+00:00",
    "value": 13.8696,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-16T23:45:00+00:00",
    "value": 13.8696,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T00:00:00+00:00",
    "value": 13.8696,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T00:15:00+00:00",
    "value": 13.8696,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T00:30:00+00:00",
    "value": 13.8696,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T00:45:00+00:00",
    "value": 13.8696,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T01:00:00+00:00",
    "value": 13.8402,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T01:15:00+00:00",
    "value": 14.1259,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T01:30:00+00:00",
    "value": 14.1259,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T01:45:00+00:00",
    "value": 14.1259,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T02:00:00+00:00",
    "value": 14.1259,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T02:15:00+00:00",
    "value": 14.1259,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T02:30:00+00:00",
    "value": 14.1259,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T02:45:00+00:00",
    "value": 14.1259,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T03:00:00+00:00",
    "value": 14.0372,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T03:15:00+00:00",
    "value": 13.6905,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T03:30:00+00:00",
    "value": 13.6905,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T03:45:00+00:00",
    "value": 13.6905,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T04:00:00+00:00",
    "value": 13.6905,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T04:15:00+00:00",
    "value": 13.6905,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T04:30:00+00:00",
    "value": 13.6905,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T04:45:00+00:00",
    "value": 13.6905,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T05:00:00+00:00",
    "value": 13.6905,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T05:15:00+00:00",
    "value": 13.1172,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T05:30:00+00:00",
    "value": 13.0491,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T05:45:00+00:00",
    "value": 13.0491,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T06:00:00+00:00",
    "value": 13.0491,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T06:15:00+00:00",
    "value": 13.0491,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T06:30:00+00:00",
    "value": 13.0491,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T06:45:00+00:00",
    "value": 13.0491,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T07:00:00+00:00",
    "value": 12.8332,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T07:15:00+00:00",
    "value": 12.8332,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T07:30:00+00:00",
    "value": 12.7579,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T07:45:00+00:00",
    "value": 12.7579,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T08:00:00+00:00",
    "value": 12.7579,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T08:15:00+00:00",
    "value": 12.7579,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T08:30:00+00:00",
    "value": 12.7579,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T08:45:00+00:00",
    "value": 12.6884,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T09:00:00+00:00",
    "value": 12.6884,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T09:15:00+00:00",
    "value": 12.6884,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T09:30:00+00:00",
    "value": 12.7337,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T09:45:00+00:00",
    "value": 12.7337,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T10:00:00+00:00",
    "value": 12.7337,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T10:15:00+00:00",
    "value": 12.7337,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T10:30:00+00:00",
    "value": 12.7337,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T10:45:00+00:00",
    "value": 12.7337,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T11:00:00+00:00",
    "value": 12.7337,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T11:15:00+00:00",
    "value": 12.7337,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T11:30:00+00:00",
    "value": 13.5106,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T11:45:00+00:00",
    "value": 13.5106,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T12:00:00+00:00",
    "value": 15.0658,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T12:15:00+00:00",
    "value": 15.0658,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T12:30:00+00:00",
    "value": 15.0658,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T12:45:00+00:00",
    "value": 15.0658,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T13:00:00+00:00",
    "value": 15.0658,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T13:15:00+00:00",
    "value": 14.5968,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T13:30:00+00:00",
    "value": 14.5968,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T13:45:00+00:00",
    "value": 14.5968,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T14:00:00+00:00",
    "value": 14.2617,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T14:15:00+00:00",
    "value": 14.2617,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T14:30:00+00:00",
    "value": 14.2617,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T14:45:00+00:00",
    "value": 14.2617,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T15:00:00+00:00",
    "value": 14.5084,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T15:15:00+00:00",
    "value": 14.5084,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T15:30:00+00:00",
    "value": 14.5395,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T15:45:00+00:00",
    "value": 14.5395,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T16:00:00+00:00",
    "value": 14.5395,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T16:15:00+00:00",
    "value": 14.5395,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T16:30:00+00:00",
    "value": 14.5395,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T16:45:00+00:00",
    "value": 14.5395,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T17:00:00+00:00",
    "value": 13.5645,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T17:15:00+00:00",
    "value": 13.5645,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T17:30:00+00:00",
    "value": 13.3502,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T17:45:00+00:00",
    "value": 13.3502,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T18:00:00+00:00",
    "value": 13.3502,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T18:15:00+00:00",
    "value": 13.3502,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T18:30:00+00:00",
    "value": 13.3502,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T18:45:00+00:00",
    "value": 13.3502,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T19:00:00+00:00",
    "value": 13.3502,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T19:15:00+00:00",
    "value": 12.6384,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T19:30:00+00:00",
    "value": 12.2999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T19:45:00+00:00",
    "value": 12.2999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T20:00:00+00:00",
    "value": 12.2999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T20:15:00+00:00",
    "value": 12.2999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T20:30:00+00:00",
    "value": 12.2999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T20:45:00+00:00",
    "value": 12.2999,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T21:00:00+00:00",
    "value": 12.3855,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T21:15:00+00:00",
    "value": 12.8572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T21:30:00+00:00",
    "value": 12.8572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T21:45:00+00:00",
    "value": 12.8572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T22:00:00+00:00",
    "value": 12.8572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T22:15:00+00:00",
    "value": 12.8572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T22:30:00+00:00",
    "value": 12.8572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T22:45:00+00:00",
    "value": 12.8572,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T23:00:00+00:00",
    "value": 12.0302,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T23:15:00+00:00",
    "value": 12.0302,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T23:30:00+00:00",
    "value": 10.7979,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-17T23:45:00+00:00",
    "value": 10.7979,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T00:00:00+00:00",
    "value": 10.7979,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T00:15:00+00:00",
    "value": 10.7979,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T00:30:00+00:00",
    "value": 10.7979,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T00:45:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T01:00:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T01:15:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T01:30:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T01:45:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T02:00:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T02:15:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T02:30:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T02:45:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T03:00:00+00:00",
    "value": 10.145,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T03:15:00+00:00",
    "value": 10.0532,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T03:30:00+00:00",
    "value": 7.7077,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T03:45:00+00:00",
    "value": 7.7077,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T04:00:00+00:00",
    "value": 7.7077,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T04:15:00+00:00",
    "value": 7.7077,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T04:30:00+00:00",
    "value": 7.7077,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T04:45:00+00:00",
    "value": 7.7077,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T05:00:00+00:00",
    "value": 10.4364,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T05:15:00+00:00",
    "value": 10.7041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T05:30:00+00:00",
    "value": 10.7041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T05:45:00+00:00",
    "value": 10.7041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T06:00:00+00:00",
    "value": 10.7041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T06:15:00+00:00",
    "value": 10.7041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T06:30:00+00:00",
    "value": 10.7041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T06:45:00+00:00",
    "value": 10.7041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T07:00:00+00:00",
    "value": 10.7041,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T07:15:00+00:00",
    "value": 12.1911,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T07:30:00+00:00",
    "value": 12.1911,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T07:45:00+00:00",
    "value": 12.1911,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T08:00:00+00:00",
    "value": 12.1911,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T08:15:00+00:00",
    "value": 12.1911,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T08:30:00+00:00",
    "value": 12.1911,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T08:45:00+00:00",
    "value": 12.1911,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T09:00:00+00:00",
    "value": 12.6693,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T09:15:00+00:00",
    "value": 13.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T09:30:00+00:00",
    "value": 13.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T09:45:00+00:00",
    "value": 13.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T10:00:00+00:00",
    "value": 13.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T10:15:00+00:00",
    "value": 13.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T10:30:00+00:00",
    "value": 13.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T10:45:00+00:00",
    "value": 13.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T11:00:00+00:00",
    "value": 13.4935,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T11:15:00+00:00",
    "value": 12.8527,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T11:30:00+00:00",
    "value": 12.5679,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T11:45:00+00:00",
    "value": 12.5679,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T12:00:00+00:00",
    "value": 12.5679,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T12:15:00+00:00",
    "value": 12.5679,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T12:30:00+00:00",
    "value": 12.5679,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T12:45:00+00:00",
    "value": 12.5679,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T13:00:00+00:00",
    "value": 12.5679,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T13:15:00+00:00",
    "value": 11.9409,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T13:30:00+00:00",
    "value": 11.9409,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T13:45:00+00:00",
    "value": 11.8367,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T14:00:00+00:00",
    "value": 11.8367,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T14:15:00+00:00",
    "value": 11.8367,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T14:30:00+00:00",
    "value": 11.8367,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T14:45:00+00:00",
    "value": 11.8367,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T15:00:00+00:00",
    "value": 11.8367,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T15:15:00+00:00",
    "value": 10.887,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T15:30:00+00:00",
    "value": 10.887,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T15:45:00+00:00",
    "value": 10.887,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T16:00:00+00:00",
    "value": 10.887,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T16:15:00+00:00",
    "value": 10.887,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T16:30:00+00:00",
    "value": 10.887,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T16:45:00+00:00",
    "value": 11.0624,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T17:00:00+00:00",
    "value": 11.0624,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T17:15:00+00:00",
    "value": 12.0208,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T17:30:00+00:00",
    "value": 12.0208,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T17:45:00+00:00",
    "value": 12.5975,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T18:00:00+00:00",
    "value": 12.5975,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T18:15:00+00:00",
    "value": 12.5975,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T18:30:00+00:00",
    "value": 12.5975,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T18:45:00+00:00",
    "value": 12.5975,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T19:00:00+00:00",
    "value": 12.5975,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T19:15:00+00:00",
    "value": 13.3784,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T19:30:00+00:00",
    "value": 13.921,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T19:45:00+00:00",
    "value": 13.921,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T20:00:00+00:00",
    "value": 13.921,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T20:15:00+00:00",
    "value": 13.921,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T20:30:00+00:00",
    "value": 15.1323,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T20:45:00+00:00",
    "value": 15.1323,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T21:00:00+00:00",
    "value": 14.5537,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T21:15:00+00:00",
    "value": 14.5537,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T21:30:00+00:00",
    "value": 14.5537,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T21:45:00+00:00",
    "value": 14.5537,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T22:00:00+00:00",
    "value": 14.5537,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T22:15:00+00:00",
    "value": 14.5537,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T22:30:00+00:00",
    "value": 14.5537,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T22:45:00+00:00",
    "value": 13.0343,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T23:00:00+00:00",
    "value": 11.8043,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T23:15:00+00:00",
    "value": 11.8043,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T23:30:00+00:00",
    "value": 11.8043,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-18T23:45:00+00:00",
    "value": 11.8043,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T00:00:00+00:00",
    "value": 11.8043,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T00:15:00+00:00",
    "value": 11.8043,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T00:30:00+00:00",
    "value": 11.8043,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T00:45:00+00:00",
    "value": 11.8043,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T01:00:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T01:15:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T01:30:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T01:45:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T02:00:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T02:15:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T02:30:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T02:45:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T03:00:00+00:00",
    "value": 14.7444,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T03:15:00+00:00",
    "value": 12.721,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T03:30:00+00:00",
    "value": 12.721,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T03:45:00+00:00",
    "value": 12.721,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T04:00:00+00:00",
    "value": 12.721,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T04:15:00+00:00",
    "value": 12.721,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T04:30:00+00:00",
    "value": 12.721,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T04:45:00+00:00",
    "value": 12.721,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T05:00:00+00:00",
    "value": 13.3303,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T05:15:00+00:00",
    "value": 13.3303,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T05:30:00+00:00",
    "value": 13.6513,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T05:45:00+00:00",
    "value": 13.6513,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T06:00:00+00:00",
    "value": 13.6513,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T06:15:00+00:00",
    "value": 13.6513,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T06:30:00+00:00",
    "value": 13.6513,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T06:45:00+00:00",
    "value": 13.1564,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T07:00:00+00:00",
    "value": 13.4293,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T07:15:00+00:00",
    "value": 13.4293,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T07:30:00+00:00",
    "value": 13.4293,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T07:45:00+00:00",
    "value": 13.4293,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T08:00:00+00:00",
    "value": 13.4293,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T08:15:00+00:00",
    "value": 13.4293,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T08:30:00+00:00",
    "value": 13.4293,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T08:45:00+00:00",
    "value": 14.0008,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T09:00:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T09:15:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T09:30:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T09:45:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T10:00:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T10:15:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T10:30:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T10:45:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T11:00:00+00:00",
    "value": 13.6156,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T11:15:00+00:00",
    "value": 13.9009,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T11:30:00+00:00",
    "value": 13.7194,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T11:45:00+00:00",
    "value": 13.7194,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T12:00:00+00:00",
    "value": 13.7194,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T12:15:00+00:00",
    "value": 13.7194,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T12:30:00+00:00",
    "value": 13.7194,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T12:45:00+00:00",
    "value": 13.7194,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T13:00:00+00:00",
    "value": 13.7194,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T13:15:00+00:00",
    "value": 13.761,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T13:30:00+00:00",
    "value": 13.761,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T13:45:00+00:00",
    "value": 14.0206,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T14:00:00+00:00",
    "value": 14.0206,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T14:15:00+00:00",
    "value": 14.0206,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T14:30:00+00:00",
    "value": 14.0206,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T14:45:00+00:00",
    "value": 14.0206,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T15:00:00+00:00",
    "value": 14.0206,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T15:15:00+00:00",
    "value": 13.6001,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T15:30:00+00:00",
    "value": 13.6001,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T15:45:00+00:00",
    "value": 13.6001,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T16:00:00+00:00",
    "value": 13.6001,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T16:15:00+00:00",
    "value": 13.6001,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T16:30:00+00:00",
    "value": 13.6001,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T16:45:00+00:00",
    "value": 13.6001,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T17:00:00+00:00",
    "value": 13.6001,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T17:15:00+00:00",
    "value": 13.3129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T17:30:00+00:00",
    "value": 13.3129,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T17:45:00+00:00",
    "value": 13.8533,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T18:00:00+00:00",
    "value": 13.8533,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T18:15:00+00:00",
    "value": 13.8533,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T18:30:00+00:00",
    "value": 13.8533,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T18:45:00+00:00",
    "value": 13.8533,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T19:00:00+00:00",
    "value": 13.8533,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T19:15:00+00:00",
    "value": 13.8533,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T19:30:00+00:00",
    "value": 14.0485,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T19:45:00+00:00",
    "value": 14.0485,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T20:00:00+00:00",
    "value": 14.6381,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T20:15:00+00:00",
    "value": 14.6381,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T20:30:00+00:00",
    "value": 14.6381,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T20:45:00+00:00",
    "value": 14.6381,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T21:00:00+00:00",
    "value": 14.3551,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T21:15:00+00:00",
    "value": 14.3551,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T21:30:00+00:00",
    "value": 14.3551,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T21:45:00+00:00",
    "value": 14.6092,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T22:00:00+00:00",
    "value": 14.6092,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T22:15:00+00:00",
    "value": 14.6092,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T22:30:00+00:00",
    "value": 14.6092,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T22:45:00+00:00",
    "value": 15.4466,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T23:00:00+00:00",
    "value": 15.4466,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T23:15:00+00:00",
    "value": 15.4466,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T23:30:00+00:00",
    "value": 15.3024,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-19T23:45:00+00:00",
    "value": 15.3024,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T00:00:00+00:00",
    "value": 15.3024,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T00:15:00+00:00",
    "value": 15.3024,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T00:30:00+00:00",
    "value": 15.3024,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T00:45:00+00:00",
    "value": 15.3024,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T01:00:00+00:00",
    "value": 15.3024,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T01:15:00+00:00",
    "value": 14.8123,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T01:30:00+00:00",
    "value": 14.2276,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T01:45:00+00:00",
    "value": 14.2276,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T02:00:00+00:00",
    "value": 14.2276,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T02:15:00+00:00",
    "value": 14.2276,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T02:30:00+00:00",
    "value": 14.2276,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T02:45:00+00:00",
    "value": 14.2276,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T03:00:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T03:15:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T03:30:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T03:45:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T04:00:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T04:15:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T04:30:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T04:45:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T05:00:00+00:00",
    "value": 14.3859,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T05:15:00+00:00",
    "value": 14.5459,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T05:30:00+00:00",
    "value": 14.5459,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T05:45:00+00:00",
    "value": 14.5459,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T06:00:00+00:00",
    "value": 14.5459,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T06:15:00+00:00",
    "value": 14.5459,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T06:30:00+00:00",
    "value": 14.5459,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T06:45:00+00:00",
    "value": 14.5459,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T07:00:00+00:00",
    "value": 14.5459,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T07:15:00+00:00",
    "value": 13.3898,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T07:30:00+00:00",
    "value": 13.3898,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T07:45:00+00:00",
    "value": 13.3898,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T08:00:00+00:00",
    "value": 13.3898,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T08:15:00+00:00",
    "value": 13.3898,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T08:30:00+00:00",
    "value": 13.3898,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T08:45:00+00:00",
    "value": 13.3898,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T09:00:00+00:00",
    "value": 13.3898,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T09:15:00+00:00",
    "value": 11.9406,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T09:30:00+00:00",
    "value": 11.9406,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T09:45:00+00:00",
    "value": 11.9406,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T10:00:00+00:00",
    "value": 11.9406,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T10:15:00+00:00",
    "value": 11.9406,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T10:30:00+00:00",
    "value": 11.9406,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T10:45:00+00:00",
    "value": 11.9406,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T11:00:00+00:00",
    "value": 11.9406,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T11:15:00+00:00",
    "value": 13.8333,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T11:30:00+00:00",
    "value": 13.6484,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T11:45:00+00:00",
    "value": 13.6484,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T12:00:00+00:00",
    "value": 13.6484,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T12:15:00+00:00",
    "value": 13.6484,
    "error": null
  },
  {
    "feature": "p_fcao_pi_7d_soft",
    "timestamp": "2025-01-20T12:30:00+00:00",
    "value": 13.6484,
    "error": null
  }
]
