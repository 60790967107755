{
  "category.combustion-analyser": [
    {
      "type": 0,
      "value": "Combustion Analyser"
    }
  ],
  "category.kpis": [
    {
      "type": 0,
      "value": "KPIs"
    }
  ],
  "category.soft-sensors": [
    {
      "type": 0,
      "value": "Soft Sensors"
    }
  ],
  "page.SHC-KPI.label": [
    {
      "type": 0,
      "value": "SHC KPI"
    }
  ],
  "page.SHC-KPI.options.chartConfigs.p_shc.title": [
    {
      "type": 0,
      "value": "Weekly SHC KPI"
    }
  ],
  "page.SHC-KPI.options.chartConfigs.p_shc.xAxisLabel": [
    {
      "type": 0,
      "value": "Year - Week"
    }
  ],
  "page.SHC-KPI.options.chartConfigs.p_shc.yAxisLabel": [
    {
      "type": 0,
      "value": "SHC"
    }
  ],
  "page.fcao-ss-utilization-KPI.label": [
    {
      "type": 0,
      "value": "Quality Module Control Utilization"
    }
  ],
  "page.fcao-ss-utilization-KPI.options.chartConfigs.s_c_sol_quality_control_utilisation.title": [
    {
      "type": 0,
      "value": "Weekly Quality Module Control Utilization (All time)"
    }
  ],
  "page.fcao-ss-utilization-KPI.options.chartConfigs.s_c_sol_quality_control_utilisation.xAxisLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "page.fcao-ss-utilization-KPI.options.chartConfigs.s_c_sol_quality_control_utilisation.yAxisLabel": [
    {
      "type": 0,
      "value": "Utilization (All time)"
    }
  ],
  "page.free-lime-kpi.label": [
    {
      "type": 0,
      "value": "Weekly Free Lime KPI"
    }
  ],
  "page.free-lime-kpi.options.bottomAxisTitle": [
    {
      "type": 0,
      "value": "Year - Week"
    }
  ],
  "page.free-lime-kpi.options.chartConfig.xAxisLabel": [
    {
      "type": 0,
      "value": "Year - Week"
    }
  ],
  "page.free-lime-kpi.options.chartConfig.yAxisLabel": [
    {
      "type": 0,
      "value": "Free Lime"
    }
  ],
  "page.free-lime-kpi.options.rightAxisOptions.0.axisLabel": [
    {
      "type": 0,
      "value": "Free Lime"
    }
  ],
  "page.historical-free-lime.label": [
    {
      "type": 0,
      "value": "Free Lime"
    }
  ],
  "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao.title": [
    {
      "type": 0,
      "value": "Measured"
    }
  ],
  "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao.xAxisLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao.yAxisLabel": [
    {
      "type": 0,
      "value": "Free Lime"
    }
  ],
  "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao_soft.title": [
    {
      "type": 0,
      "value": "Modelled"
    }
  ],
  "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao_soft.xAxisLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "page.historical-free-lime.options.chartConfigs.s_c_sol_fcao_soft.yAxisLabel": [
    {
      "type": 0,
      "value": "Free Lime"
    }
  ],
  "page.historical-litre-weight.label": [
    {
      "type": 0,
      "value": "Clinker Litre Weight"
    }
  ],
  "page.historical-litre-weight.options.chartConfigs.s_c_sol_litre_weight.title": [
    {
      "type": 0,
      "value": "Measured"
    }
  ],
  "page.historical-litre-weight.options.chartConfigs.s_c_sol_litre_weight.xAxisLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "page.historical-litre-weight.options.chartConfigs.s_c_sol_litre_weight.yAxisLabel": [
    {
      "type": 0,
      "value": "Clinker Litre Weight"
    }
  ],
  "page.historical-litre-weight.options.chartConfigs.s_c_sol_litre_weight_soft.title": [
    {
      "type": 0,
      "value": "Modelled"
    }
  ],
  "page.historical-litre-weight.options.chartConfigs.s_c_sol_litre_weight_soft.xAxisLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "page.historical-litre-weight.options.chartConfigs.s_c_sol_litre_weight_soft.yAxisLabel": [
    {
      "type": 0,
      "value": "Clinker Litre Weight"
    }
  ],
  "page.historical-performance-free-lime.label": [
    {
      "type": 0,
      "value": "Free Lime Performance"
    }
  ],
  "page.historical-performance-free-lime.options.title": [
    {
      "type": 0,
      "value": "Performance Improvement Score"
    }
  ],
  "page.historical-performance-free-lime.options.xAxisLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "page.historical-performance-free-lime.options.yAxisLabel": [
    {
      "type": 0,
      "value": "Performance Improvement"
    }
  ],
  "page.historical-performance-litre-weight.label": [
    {
      "type": 0,
      "value": "Litre Weight Performance"
    }
  ],
  "page.historical-performance-litre-weight.options.title": [
    {
      "type": 0,
      "value": "Performance Improvement Score"
    }
  ],
  "page.historical-performance-litre-weight.options.xAxisLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "page.historical-performance-litre-weight.options.yAxisLabel": [
    {
      "type": 0,
      "value": "Performance Improvement"
    }
  ],
  "page.kiln-inlet.label": [
    {
      "type": 0,
      "value": "Kiln Inlet"
    }
  ],
  "page.kiln-inlet.options.chartTitle": [
    {
      "type": 0,
      "value": "Kiln Inlet"
    }
  ],
  "page.kiln-inlet.options.leftFeature.title": [
    {
      "type": 0,
      "value": "Calibrated O2"
    }
  ],
  "page.kiln-inlet.options.rightFeature.title": [
    {
      "type": 0,
      "value": "Calibrated CO"
    }
  ],
  "page.preheater.label": [
    {
      "type": 0,
      "value": "Preheater"
    }
  ],
  "page.preheater.options.chartTitle": [
    {
      "type": 0,
      "value": "Preheater"
    }
  ],
  "page.preheater.options.leftFeature.title": [
    {
      "type": 0,
      "value": "Calibrated O2"
    }
  ],
  "page.preheater.options.performanceFeature.title": [
    {
      "type": 0,
      "value": "O2"
    }
  ],
  "page.preheater.options.rightFeature.title": [
    {
      "type": 0,
      "value": "Calibrated CO"
    }
  ],
  "page.today.label": [
    {
      "type": 0,
      "value": "Today"
    }
  ],
  "page.today.options.alarmConfigs.0.feature.label": [
    {
      "type": 0,
      "value": "Kiln Inlet O2"
    }
  ],
  "page.today.options.alarmConfigs.1.feature.label": [
    {
      "type": 0,
      "value": "Kiln Inlet NOx"
    }
  ],
  "page.today.options.alarmConfigs.2.feature.label": [
    {
      "type": 0,
      "value": "Cyclone 5 Top Temperature"
    }
  ],
  "page.today.options.alarmConfigs.3.feature.label": [
    {
      "type": 0,
      "value": "BZT"
    }
  ],
  "page.today.options.alarmConfigs.4.feature.label": [
    {
      "type": 0,
      "value": "Kiln Torque"
    }
  ],
  "page.today.options.alarmConfigs.5.feature.label": [
    {
      "type": 0,
      "value": "Clinker Litre Weight"
    }
  ],
  "page.today.options.alarmConfigs.6.feature.label": [
    {
      "type": 0,
      "value": "Clinker LSF"
    }
  ],
  "page.today.options.alarmConfigs.7.feature.label": [
    {
      "type": 0,
      "value": "Free Lime Clinker"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.0.leftFeatureConfig.feature.label": [
    {
      "type": 0,
      "value": "Calibrated O2"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.0.performanceFeatureConfig.highLabel": [
    {
      "type": 0,
      "value": "High O2"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.0.performanceFeatureConfig.lowLabel": [
    {
      "type": 0,
      "value": "Low O2"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.0.rightFeatureConfig.feature.label": [
    {
      "type": 0,
      "value": "Calibrated CO"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.0.title": [
    {
      "type": 0,
      "value": "Kiln Inlet"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.1.leftFeatureConfig.feature.label": [
    {
      "type": 0,
      "value": "Calibrated O2"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.1.performanceFeatureConfig.highLabel": [
    {
      "type": 0,
      "value": "High O2"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.1.performanceFeatureConfig.lowLabel": [
    {
      "type": 0,
      "value": "Low O2"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.1.rightFeatureConfig.feature.label": [
    {
      "type": 0,
      "value": "Calibrated CO"
    }
  ],
  "page.today.options.gasAnalyserChartConfigs.1.title": [
    {
      "type": 0,
      "value": "Preheater"
    }
  ],
  "page.today.options.kpiChartConfigs.0.feature.label": [
    {
      "type": 0,
      "value": "Specific Heat Consumption"
    }
  ],
  "page.today.options.qualityChartConfigs.0.label": [
    {
      "type": 0,
      "value": "Clinker Free Lime"
    }
  ],
  "page.today.options.qualityChartConfigs.0.measured.label": [
    {
      "type": 0,
      "value": "Free Lime Sample"
    }
  ],
  "page.today.options.qualityChartConfigs.0.modelled.label": [
    {
      "type": 0,
      "value": "Free Lime Prediction"
    }
  ],
  "page.today.options.qualityChartConfigs.1.label": [
    {
      "type": 0,
      "value": "Clinker Litre Weight"
    }
  ],
  "page.today.options.qualityChartConfigs.1.measured.label": [
    {
      "type": 0,
      "value": "Litre Weight Sample"
    }
  ],
  "page.today.options.qualityChartConfigs.1.modelled.label": [
    {
      "type": 0,
      "value": "Litre Weight Prediction"
    }
  ],
  "page.today.options.recommendationDataFeatureConfigs.0.feature.label": [
    {
      "type": 0,
      "value": "Preheater O2 Target"
    }
  ],
  "page.today.options.recommendationDataFeatureConfigs.0.target.label": [
    {
      "type": 0,
      "value": "Preheater O2 Target"
    }
  ],
  "page.weekly-kiln-O2-performance.label": [
    {
      "type": 0,
      "value": "Weekly Kiln O2 Performance KPI"
    }
  ],
  "page.weekly-kiln-O2-performance.options.chartTitle": [
    {
      "type": 0,
      "value": "Weekly Kiln O2 Performance"
    }
  ],
  "page.weekly-kiln-O2-performance.options.leftAxisTitle": [
    {
      "type": 0,
      "value": "Time (%)"
    }
  ],
  "page.weekly-kiln-O2-performance.options.toggleOptions.0.buttonText": [
    {
      "type": 0,
      "value": "Incident Count"
    }
  ],
  "page.weekly-kiln-O2-performance.options.toggleOptions.0.rightAxisTitle": [
    {
      "type": 0,
      "value": "Incident Count"
    }
  ],
  "page.weekly-kiln-O2-performance.options.toggleOptions.1.buttonText": [
    {
      "type": 0,
      "value": "Incident Average Length"
    }
  ],
  "page.weekly-kiln-O2-performance.options.toggleOptions.1.rightAxisTitle": [
    {
      "type": 0,
      "value": "Incident Average Length (min)"
    }
  ],
  "page.weekly-ph-O2-performance.label": [
    {
      "type": 0,
      "value": "Weekly Preheater O2 Performance KPI"
    }
  ],
  "page.weekly-ph-O2-performance.options.chartTitle": [
    {
      "type": 0,
      "value": "Weekly Preheater O2 Performance"
    }
  ],
  "page.weekly-ph-O2-performance.options.leftAxisTitle": [
    {
      "type": 0,
      "value": "Time (%)"
    }
  ],
  "page.weekly-ph-O2-performance.options.toggleOptions.0.buttonText": [
    {
      "type": 0,
      "value": "Incident Count"
    }
  ],
  "page.weekly-ph-O2-performance.options.toggleOptions.0.rightAxisTitle": [
    {
      "type": 0,
      "value": "Incident Count"
    }
  ],
  "page.weekly-ph-O2-performance.options.toggleOptions.1.buttonText": [
    {
      "type": 0,
      "value": "Incident Average Length"
    }
  ],
  "page.weekly-ph-O2-performance.options.toggleOptions.1.rightAxisTitle": [
    {
      "type": 0,
      "value": "Incident Average Length (min)"
    }
  ]
}
