import { useIntl } from 'react-intl';
import { DESCRIPTOR as DateTimeDescriptor } from '../../../messages/DateTime';
import { FeatureName } from '../../../types';
import { useLocalisedLabel } from '../../../utils';
import { Options } from '../schema';

interface ColumnData {
  field: string | FeatureName;
  headerName: string;
  width: number;
}

const useColumnData = (options: Options): ColumnData[] => {
  const { formatMessage } = useIntl();
  const headerName = useLocalisedLabel(options.title);
  return [
    { field: 'date', headerName: formatMessage(DateTimeDescriptor), width: 280 },
    {
      field: options.featureName,
      headerName,
      width: 280
    }
  ];
};

export default useColumnData;
