import { Static, Type } from '@sinclair/typebox';
import { LabelSchema, featureConfigSchema, featureSchema } from '../../Config/sharedSchemas';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import getTemplateSchema from '../getTemplateSchema';

export enum AlarmType {
  MAX_MID_MIN = 'MAX_MID_MIN',
  MAX_HIGH_LOW_MIN = 'MAX_HIGH_LOW_MIN'
}

const alarmConfigSchema = Type.Object(
  {
    feature: featureSchema,
    type: selectableEnum(AlarmType, {
      title: 'Alarm Type',
      description: 'The type of alarm'
    }),
    maxBound: Type.Number({ title: 'Max Bound', description: 'The max bound' }),
    highBound: Type.Number({ title: 'High Bound', description: 'The high bound' }),
    midBound: Type.Number({ title: 'Mid Bound', description: 'The mid bound' }),
    lowBound: Type.Number({ title: 'Low Bound', description: 'The low bound' }),
    minBound: Type.Number({ title: 'Min Bound', description: 'The min bound' }),
    aboveHighColour: Type.String({ title: 'Above High Colour', description: 'The colour for above high' }),
    aboveMidColour: Type.String({ title: 'Above Mid Colour', description: 'The colour for above mid' }),
    inRangeColour: Type.String({ title: 'In Range Colour', description: 'The colour for in range' }),
    belowMidColour: Type.String({ title: 'Below Mid Colour', description: 'The colour for below mid' }),
    belowLowColour: Type.String({ title: 'Below Low Colour', description: 'The colour for below low' })
  },
  {
    title: 'Alarm Config',
    description: 'The configuration for the alarm'
  }
);
export type AlarmConfig = Static<typeof alarmConfigSchema>;

const kpiChartConfigSchema = Type.Object(
  {
    feature: featureSchema,
    rangeStart: Type.Optional(
      Type.Number({ title: 'Range Start', description: 'The start of the range on the Y axis' })
    ),
    rangeEnd: Type.Optional(Type.Number({ title: 'Range End', description: 'The end of the range on the Y axis' }))
  },
  {
    title: 'KPI Chart Config',
    description: 'The configuration for the single KPI chart to display'
  }
);
export type KpiChartConfig = Static<typeof kpiChartConfigSchema>;

const qualityChartConfigSchema = Type.Object(
  {
    label: LabelSchema({ title: 'Label', description: 'The label for the chart' }),
    measured: featureSchema,
    modelled: featureSchema,
    roundValues: Type.Optional(
      Type.Boolean({ title: 'Round values', description: 'Round values to nearest whole number' })
    ),
    rangeStart: Type.Optional(
      Type.Number({ title: 'Range Start', description: 'The start of the range on the Y axis' })
    ),
    rangeEnd: Type.Optional(Type.Number({ title: 'Range End', description: 'The end of the range on the Y axis' })),
    to: Type.Optional(Type.String({ title: 'To', description: 'The page to redirect when clicking on the chart' })),
    sampleRate: Type.Optional(
      Type.Number({ title: 'Sample Rate', description: 'Sample rate to display data points in mins (15 if unset)' })
    )
  },
  {
    title: 'Quality Chart Config',
    description: 'The configuration for the quality chart to display'
  }
);
export type QualityChartConfig = Static<typeof qualityChartConfigSchema>;

const gasAnalyserFeatureConfigSchema = Type.Object(
  {
    feature: featureSchema,
    rangeStart: Type.Optional(
      Type.Number({ title: 'Range Start', description: 'The start of the range on the Y axis' })
    ),
    rangeEnd: Type.Optional(Type.Number({ title: 'Range End', description: 'The end of the range on the Y axis' }))
  },
  {
    title: 'Gas Analyser Feature Config',
    description: 'The configuration for the gas analyser feature'
  }
);
export type GasAnalyserFeatureConfig = Static<typeof gasAnalyserFeatureConfigSchema>;

const performanceFeatureConfigSchema = Type.Object(
  {
    name: selectableEnum(FeatureName, {
      title: 'Feature Name',
      description: 'The name of the feature'
    }),
    highLabel: LabelSchema({ title: 'High Label', description: 'The label for the high value' }),
    lowLabel: LabelSchema({ title: 'Low Label', description: 'The label for the low value' })
  },
  {
    title: 'Performance Feature Config',
    description: 'The configuration for the performance feature'
  }
);

const gasAnalyserConfigSchema = Type.Object(
  {
    title: LabelSchema({ title: 'Title', description: 'The title of the chart' }),
    leftFeatureConfig: gasAnalyserFeatureConfigSchema,
    rightFeatureConfig: gasAnalyserFeatureConfigSchema,
    performanceFeatureConfig: performanceFeatureConfigSchema,
    to: Type.Optional(Type.String({ title: 'To', description: 'The to value' }))
  },
  {
    title: 'Gas Analyser Chart Config',
    description: 'The configuration for the gas analyser chart to display'
  }
);
export type GasAnalyserChartConfig = Static<typeof gasAnalyserConfigSchema>;

const options = Type.Object(
  {
    gasAnalyserChartConfigs: Type.Array(gasAnalyserConfigSchema, {
      title: 'Gas Analyser Chart Configs',
      description: 'The configuration for all the gas analyser charts to display'
    }),
    qualityChartConfigs: Type.Array(qualityChartConfigSchema, {
      title: 'Quality Chart Configs',
      description: 'The configuration for all the quality charts to display'
    }),
    kpiChartConfigs: Type.Array(kpiChartConfigSchema, {
      title: 'KPI Chart Configs',
      description: 'The configuration for all the KPI charts to display'
    }),
    predictionDataFeatureConfigs: Type.Array(featureConfigSchema, {
      title: 'Prediction Data Feature Configs',
      description: 'The configuration for all the prediction data features'
    }),
    alarmConfigs: Type.Array(alarmConfigSchema, {
      title: 'Alarm Configs',
      description: 'The configuration for all the alarms'
    }),
    newRecommendationFlagTime: Type.Number({
      title: 'New Recommendation Flag Time',
      description: 'The time to flag a new recommendation'
    }),
    recommendationLifespan: Type.Optional(
      Type.Number({ title: 'Recommendation Lifespan', description: 'The lifespan of the recommendation' })
    ),
    maxAlarmConfigsCount: Type.Number({ title: 'Max Config Count', description: 'The max config count', default: 6 })
  },
  { title: 'Options', description: 'Options for the Today page template' }
);

export const templateSlug = 'TodayPage';
export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Today Page',
  description: 'Today Page page for new config etc etc'
});
export type TemplateSchema = Static<typeof templateSchema>;
export const templateUiSchema = {
  featureFlag: {
    'ui:options': {
      // placeholder to show how to set a field option
      disabled: false
    }
  }
};
